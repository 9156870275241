html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
}

/* .webfont {
  transform: rotate(0.03deg);
} */

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::-moz-selection {
  color: #fff;
  background: #296a8b;
}
::-webkit-selection {
  color: #fff;
  background: #296a8b;
}
::selection {
  color: #fff;
  background: #296a8b;
}

.mapboxgl-popup-content {
  color: rgba(0, 0, 0, 0.87);
}
